import { NgIf } from '@angular/common';
import { Component, ElementRef, input, viewChildren } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { TMenuItemsString } from '@models/commonTypes';

@Component({
  selector: 'app-tabs',
  imports: [RouterLink, RouterLinkActive, NgIf],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent {
  links = viewChildren<ElementRef>('tabs_link');

  tabList = input.required<TMenuItemsString>();
  countsList = input<number[]>([]);

  scrollToLink(index: number) {
    this.links()[index].nativeElement.scrollIntoView({ block: 'end' });

    // Подскролл к следующей ссылке
    const tabs = document.getElementById('tabs');
    if (tabs && index !== 0 && index !== this.tabList.length - 1)
      tabs.scrollBy(30, 0);
  }
}
